import { FunctionComponent } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { differenceInDays } from "date-fns";

import { useBudgetData } from "../../../../hooks/use-budget-data";
import { featureFlagNames } from "../../../../util/feature-flags";
import { BudgetReminderBanner } from "../BudgetReminderBanner/BudgetReminderBanner";
import { JobFamilyNavigation } from "../JobFamilyNavigation/JobFamilyNavigation";

import styles from "./styles.scss";

const BUDGET_REMINDER_BANNER_DAYS_THRESHOLD = 60;

export const SecondaryHeaderMenuBar: FunctionComponent = () => {
  const budgetData = useBudgetData();
  const homepageOptimisationPhase1 = useFeatureIsOn(
    featureFlagNames.homepageOptimisationPhase1,
  );
  const budgetReminderBannerValue = useFeatureIsOn(
    featureFlagNames.budgetReminderBanner,
  );

  const shouldShowBudgetReminderBanner =
    !!budgetReminderBannerValue &&
    !!budgetData &&
    budgetData.remainingBudgetAmount > 0 &&
    differenceInDays(budgetData.endDate, new Date()) <
      BUDGET_REMINDER_BANNER_DAYS_THRESHOLD;

  const shouldShowJobFamilyNavigation =
    !!homepageOptimisationPhase1 && !shouldShowBudgetReminderBanner;

  if (shouldShowBudgetReminderBanner) {
    return (
      <div className={styles.subRow}>
        <BudgetReminderBanner budgetData={budgetData} />
      </div>
    );
  }

  if (shouldShowJobFamilyNavigation) {
    return (
      <div className={styles.subRow}>
        <JobFamilyNavigation />
      </div>
    );
  }

  return null;
};
